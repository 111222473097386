<template>
	<div>
		<div class="service-modal animated fadeInUpBig" v-show="visible">
			<div class="service-title-box">
				<div class="title">慧决策-建筑企业经营决策支持平台</div>
				<!-- <div class="title">安徽数智建造研究院有限公司</div> -->
				<i class="el-icon-close" @click="close"></i>
			</div>
			<div class="service-main">
				<div class="service-main-left">
					<div class="contact-us">联系我们</div>
					<div class="code-container">
						<!-- <div class="code-box">
              <img
                @dragstart.prevent
                class="code-img"
                src="../../../assets/img/code-one.png"
                alt=""
              />
              <div>客服微信</div>
            </div> -->
						<div class="code-box">
							<img @dragstart.prevent class="code-img" :src="decObj.dataDescription" alt="" style="transform: scale(0.92);" />
							<div>客服二维码</div>
						</div>
						<div class="code-box">
							<img @dragstart.prevent class="code-img" src="../../../../public/wx.jpg" alt="" />
							<div>进入小程序</div>
						</div>
						<div class="code-box">
							<img @dragstart.prevent class="code-img" src="../../../../public/app.png" alt="" />
							<div>下载APP</div>
						</div>
						<div class="code-box">
							<img @dragstart.prevent class="code-img" src="../../../assets/img/layout/gongzhonghao.png" alt="" />
							<div>关注慧决策平台</div>
						</div>
					</div>
					<div class="msg-item">
						<div class="icon"></div>
						<span>{{ servicePhone }}</span>
					</div>
					<!-- <div class="msg-item">
						<div class="icon mail"></div>
						<span>ctce_hjc@163.com</span>
					</div> -->
					<div class="msg-item">
						<div class="icon address"></div>
						<span class="address-name">安徽省合肥市包河区中铁科技大楼</span>
					</div>
				</div>
				<div class="service-main-right">
					<!-- <div class="right-msg">
						您好！当前客服不在线，如有问题请留言，我们将尽快联系您~
					</div> -->
					<el-form ref="dataPO" :model="dataPO" :rules="rules">
						<el-form-item label="留言分类" prop="messageType" class="messageTypeTitle">
							<el-radio-group v-model="dataPO.messageType" class="messageTypeBox">
								<el-radio class="messageTypeItem" v-for="item in messageTypeList" :key="item.dataId" :label="item.dictValue" :name="item.dictValue">{{ item.dictLabel }}</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="留言内容" prop="messageInfo">
							<el-input type="textarea" v-model="dataPO.messageInfo" placeholder="请输入留言内容" maxlength="500"></el-input>
						</el-form-item>
						<el-form-item label="您的联系电话" prop="contactNumber">
							<el-input v-model="dataPO.contactNumber" oninput="value=value.replace(/[^\d]/g,'')" @blur="dataPO.contactNumber = $event.target.value" placeholder="请输入联系方式"></el-input>
						</el-form-item>
					</el-form>
					<el-button type="primary" @click="submitForm">提交留言</el-button>
				</div>
			</div>
		</div>
		<login-modal ref="loginModal"></login-modal>
	</div>
</template>

<script>
import LoginModal from "../../loginModal";
import {mapGetters} from "vuex";
export default {
	props: {
		visible: {
			typeof: Boolean,
			default: false,
		},
	},
	components: {
		LoginModal,
	},
	data() {
		let checkPhone = (rule, value, callback) => {
			const reg = "^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-9])|(18[0-9])|166|198|199|191|195|193|(147))\\d{8}$";
			if (new RegExp(reg).test(value)) {
				callback();
			} else {
				return callback(new Error("请输入正确的手机号"));
			}
		};
		return {
			rules: {
				messageInfo: [{required: true, message: "请输入留言内容", trigger: "blur"}],
				contactNumber: [
					{
						required: true,
						message: "请输入您的联系方式",
						trigger: "blur",
					},
					{validator: checkPhone, trigger: "blur"},
				],
				messageType: [{required: true, message: "请选择留言分类", trigger: "blur"}],
			},
			dataPO: {
				messageInfo: "",
				contactNumber: "",
				messageType: "",
			},
			decObj: {
				dataDescription: "",
			},
			messageTypeList: [],
		};
	},
	computed: {
		...mapGetters(["servicePhone"]),
	},
	created() {
		this.getDescription();
		this.getMessageTypeList();
	},
	mounted() {},
	methods: {
		// 获取留言分类
		getMessageTypeList() {
			this.$api.home.getMessageTypeList().then(res => {
				this.messageTypeList = res.data;
				console.log("res", res);
			});
		},
		// 获取客服信息
		getDescription() {
			let params = {dataDescriptionId: "1735120381177188353"};
			this.$api.home.getDescription(params).then(res => {
				this.decObj = res.data;
			});
		},
		// 提交
		submitForm() {
			this.$refs.dataPO.validate(valid => {
				if (valid) {
					// 判断用户是否登录
					if (this.$session.getUsers()) {
						this.$api.home
							.leaveMessage(this.dataPO)
							.then(() => {
								this.$message.success("成功提交");
								this.$refs.dataPO.resetFields();
								this.$parent.visible = false;
							})
							.catch(msg => {
								if (msg?.msg) {
									this.$message.error(msg?.msg);
								}
							});
					} else {
						this.$refs.loginModal.visible = true;
					}
				} else {
					return false;
				}
			});
		},
		// 关闭弹窗
		close() {
			this.$refs.dataPO.resetFields();
			this.$parent.visible = false;
		},
	},
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>

<template>
  <div class="disclaimer-modal">
    <Modal :visible.sync="visible">
      <div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
        免责声明
      </div>
      <div class="disclaimer-container">
        <div v-html="detail"></div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
export default {
  components: {
    Modal
  },
  data() {
    return {
      visible: false,
      detail:
        '<p style="text-align: center"><strong>免责声明</strong></p><p><br></p><p>	本平台提供、显示、展示或者供您知悉的数据皆由第三方网站或者平台提供，由于第三方原因或者算法逻辑本身的局限性，本平台无法对平台上显示数据的真实性、准确性和及时性提供明示或者默示的保证。您需自行决定是否采信本平台上展示的数据。</p>'
    };
  }
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>

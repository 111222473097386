<template>
  <div>
    <div class="need-modal-container">
      <Modal :visible.sync="visible" @reset="resetFields">
        <div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
          提交需求
        </div>
        <div class="need-container">
          <el-form ref="dataPO" :model="dataPO" :rules="rules">
            <div style="display: flex">
              <el-form-item label="所在地区" prop="regionCode">
                <el-cascader
                  :append-to-body="false"
                  placeholder="请选择项目地区"
                  filterable
                  v-model="dataPO.regionCode"
                  :props="{
                    value: 'code',
                    label: 'name',
                    children: 'childrenList',
                  }"
                  :options="areaList"
                  :style="{ width: '100%' }"
                  ref="area"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="项目类型" prop="projectTypeCode">
                <el-select v-model="dataPO.projectTypeCode" placeholder="请选择项目类型">
                  <el-option
                    v-for="(item, index) in projectTypeList"
                    :label="item"
                    :value="item"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div style="display: flex">
              <el-form-item label="数据类别" prop="bidDataTypeCode">
                <el-select v-model="dataPO.bidDataTypeCode" placeholder="请选择数据类别">
                  <el-option
                    v-for="(item, index) in bidDataTypeCodeList"
                    :label="item.name"
                    :value="item.id"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="期望交付时间" prop="expectedDeliveryDate">
                <el-date-picker
                  type="date"
                  placeholder="请选择时间"
                  v-model="dataPO.expectedDeliveryDate"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </div>
            <el-form-item label="需求描述" prop="detail">
              <el-input
                type="textarea"
                v-model="dataPO.detail"
                maxlength="1000"
                placeholder="限1000字以内"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="button-box">
            <el-button type="primary" @click="submitForm">立即提交</el-button>
          </div>
        </div>
      </Modal>
    </div>
    <div>
      <login-modal ref="loginModal"></login-modal>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import LoginModal from "@/components/loginModal";
export default {
  components: {
    Modal,
    LoginModal,
  },
  data() {
    return {
      pickerOptions: {
        //禁用当前日期之前的日期
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      visible: false,
      areaList: [],
      rules: {
        regionCode: [{ required: true, message: "请选择项目地区", trigger: "change" }],
        projectTypeCode: [
          { required: true, message: "请选择项目类型", trigger: "change" },
        ],
        bidDataTypeCode: [
          { required: true, message: "请选择数据类别", trigger: "change" },
        ],
        expectedDeliveryDate: [
          { required: true, message: "请输入期望交付时间", trigger: "blur" },
        ],
        detail: [{ required: true, message: "请填写需求描述", trigger: "blur" }],
      },
      dataPO: {
        regionCode: "",
        projectTypeCode: "",
        bidDataTypeCode: "",
        detail: "",
        terminal: "60441001",
        expectedDeliveryDate: "",
      },
      projectTypeList: [],
      bidDataTypeCodeList: [
        { id: "60631001", name: "按地区" },
        { id: "60631003", name: "按行业" },
        { id: "60631002", name: "按招标单位" },
      ],
    };
  },
  methods: {
    // 省市区获取（父组件中调用）
    getAreaList() {
      this.$api.successful
        .getAreaList()
        .then((res) => {
          this.areaList = res.data;
        })
        .catch((msg) => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    // 项目类型获取（父组件中调用）
    getBidProjectType() {
      this.$api.successful
        .getBidProjectType()
        .then((res) => {
          this.projectTypeList = res.data;
        })
        .catch((msg) => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    // 提交
    submitForm() {
      this.$refs.dataPO.validate((valid) => {
        if (valid) {
          // 判断用户是否登录
          if (this.$session.getUsers()) {
            let { dataPO } = this;
            dataPO.region = this.$refs.area.inputValue;
            delete dataPO.regionCode;
            this.$api.successful
              .consultingBidDate(dataPO)
              .then(() => {
                this.$message.success("成功提交");
                this.$refs.dataPO.resetFields();
                this.visible = false;
              })
              .catch((msg) => {
                if (msg?.msg) {
                  this.$message.error(msg?.msg);
                }
              });
          } else {
            this.$refs.loginModal.visible = true;
          }
        } else {
          return false;
        }
      });
    },
    // 关闭弹窗时重置表单
    resetFields() {
      this.$refs.dataPO.resetFields();
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>

<template>
  <div>
    <div class="need-modal-container">
      <Modal :visible.sync="visible" @reset="resetFields" width="80%">
        <div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
          常见问题
        </div>
        <div class="content">
          <div class="left">
            <div class="title">问题分类</div>
            <div class="title-item line-clamp-2" :class="quesTypeIndex == index ? 'active' : ''"
              v-for="(item, index) in questionList" @click="toType(index)"  :key="index">
              {{ item.quesType }}
            </div>
          </div>
          <div class="right" v-if="questionList && questionList[quesTypeIndex]">
            <div class="line-clamp-2 title2">
              <span class="cp" @click="quesTypeChildrenIndex=null">{{ questionList[quesTypeIndex].quesType }}</span>
              <span class="" v-if="quesTypeChildrenIndex !== null">
                > {{ questionList[quesTypeIndex].children[quesTypeChildrenIndex].question }}
              </span>
            </div>
            <template v-if="quesTypeChildrenIndex === null">
              <div class="list-item" v-for="(i, index) in questionList[quesTypeIndex].children" :key="i"
                @click="toDetail(index)">
                <div class="text line-clamp-1">
                  <span style="color: #e1e1e1;margin-right: 10px;"> ● </span>{{ i.question }}</div>
                <!-- <i class="el-icon-arrow-right" color="#999999" size="15"></i> -->
              </div>
            </template>
            <template v-else>
              <div class="detail-content">
                <div class="bgfff p-16">
                  <div class="title text-center">{{questionList[quesTypeIndex].children[quesTypeChildrenIndex].question}}</div>
                  <div class="text">{{ questionList[quesTypeIndex].children[quesTypeChildrenIndex].answer }}</div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </Modal>
    </div>
    <div>
      <login-modal ref="loginModal"></login-modal>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import LoginModal from "@/components/loginModal";
export default {
  components: {
    Modal,
    LoginModal,
  },
  data() {
    return {
      visible: false,
      dataPO: {
        pageNum: 1,
        pageSize: 10000,
      },
      total: 0,
      questionList: [],
      dialogList: [],
      scrollTop: 0,
      detailVisible: false,
      detail: {},
      quesTypeIndex: 0,
      quesTypeChildrenIndex: null,
    };
  },
  methods: {

    getQuestionList() {
      this.$api.home.getQuestionList(this.dataPO).then(r => {
        if (r.rows.length) {
          let questionList = [],
            type = r.rows[0].type,
            quesType = r.rows[0].quesType
          let row = {
            type: type,
            quesType: quesType,
            children: []
          }
          r.rows.map(item => {
            if (item.type == type) {
              row.children.push(item)
            } else {
              questionList.push(row)
              type = item.type
              quesType = item.quesType
              row = {
                type: type,
                quesType: quesType,
                children: []
              }
              row.children.push(item)
            }
          })
          questionList.push(row)
          this.questionList = questionList
        }
        this.total = r.total
      }).catch((msg) => {
        if (msg?.msg) {
          this.$message.error(msg?.msg);
        }
      });
    },
    toType(index) {
      // 问题解答
      this.quesTypeChildrenIndex=null
      this.quesTypeIndex = index
    },
    toDetail(index) {
      // 问题解答
      this.quesTypeChildrenIndex = index
    },
    resetFields() {
      this.questionList = []
      this.dialogList = []
      this.dataPO.pageNum = 1
      this.scrollTop = 0
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>

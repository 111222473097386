<template>
  <div class="float-nav-container">
    <div class="float-nav" :class="isShow ? '' : 'float-nav-init'" v-show="isNeed">
      <span class="iconfont icon-kefuerji" @click="openService"></span>
      <span @click="openService" class="kefuText">客服</span>
      <div class="line"></div>
      <img src="@/assets/img/question.png" class="icon-question" alt="" @click="openQuestion">
      <span @click="openService" class="kefuText">疑问</span>
      <div class="line"></div>
      <span class="iconfont icon-jurassic_message" @click="openNeed"></span>
      <div v-if="isShow" class="line"></div>
      <span v-if="isShow" class="iconfont icon-fanhuidingbu" @click="goTopClick"></span>
    </div>
    <div
      class="float-nav"
      :class="isShow ? 'float-nav-init' : 'float-nav-start'"
      v-show="!isNeed"
    >
      <span class="iconfont icon-kefuerji" @click="openService"></span>
      <span @click="openService" class="kefuText">客服</span>
      <div class="line"></div>
      <img src="@/assets/img/question.png" class="icon-question" alt="" @click="openQuestion">
      <span @click="openService" class="kefuText">疑问</span>
      <div v-if="isShow" class="line"></div>
      <span v-if="isShow" class="iconfont icon-fanhuidingbu" @click="goTopClick"></span>
    </div>
    <service-modal :visible="visible"></service-modal>
    <need-modal ref="needModal"></need-modal>
    <question-modal ref="questionModal"></question-modal>
  </div>
</template>

<script>
import ServiceModal from "./serviceModal";
import NeedModal from "./needModal";
import QuestionModal from "./questionModal";

export default {
  props: {
    // 出现时候距顶部的距离
    move: {
      type: Number,
      default: 200,
    },
  },
  components: {
    ServiceModal,
    NeedModal,
    QuestionModal
  },
  watch: {
    $route() {
      let path = this.$route.path;
      if (path.includes("successful")) {
        this.isNeed = true;
      } else {
        this.isNeed = false;
      }
    },
  },
  created() {
    let path = this.$route.path;
    if (path.includes("successful")) {
      this.isNeed = true;
    } else {
      this.isNeed = false;
    }
  },
  data() {
    return {
      isShow: false, // 置顶是否显示
      visible: false, // 客服弹窗是否显示
      isNeed: false, // 是否展示需求提交
    };
  },
  methods: {
    // 返回顶部
    goTopClick() {
      document.documentElement.scrollIntoView({ behavior: "smooth" });
    },
    // 控制组件显示隐藏
    handleScroll() {
      let height = document.documentElement.scrollTop || document.body.scrollTop;
      if (height > this.move) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
    // 打开问题反馈弹窗
    openService() {
      this.visible = true;
    },
    // 打开提交需要弹窗
    openNeed() {
      this.$refs.needModal.visible = true;
      this.$refs.needModal.getAreaList();
      this.$refs.needModal.getBidProjectType();
    },
    // 打开提交需要弹窗
    openQuestion() {
      this.$refs.questionModal.visible = true;
      this.$refs.questionModal.getQuestionList();
    },
  },
  mounted() {
    //监听滚动条
    window.addEventListener("scroll", this.handleScroll, true);
  },
};
</script>

<style lang="less">
@import "./index.less";
</style>
